import { useExperiments, useTranslation } from '@wix/yoshi-flow-editor';
import React, { useEffect, useState } from 'react';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import {
  Dropdown,
  DropdownOptionProps,
  DropdownOptionTextOverflow,
  DropdownProps,
  DropdownTheme,
} from 'wix-ui-tpa';
import { BorderStyle } from '../../../../types/types';
import settingsParams from '../../settingsParams';
import { PriceOptionDropdownDataHooks } from './dataHooks';
import { MultiselectDropdown } from '@wix/bookings-viewer-ui';
import { classes, st } from './PriceOptionDropdown.st.css';
import { MultiselectDropdownOption } from '@wix/bookings-viewer-ui/dist/src/components/MultiselectDropdown/types';
import { ExperimentsConsts } from '../../../../consts/experiments';

export interface PriceOptionDropdownProps {
  options: PriceChoice[];
  maxParticipants: number;
  label: string;
  onDropdownClose?: (
    priceOptionData: PriceOptionNumberOfParticipants[],
  ) => void;
  onChange?: (priceOptionData: PriceOptionNumberOfParticipants[]) => void;
  showPrice?: boolean;
  error?: boolean;
  disabled?: boolean;
  errorMessage?: string;
}

export interface PriceOptionNumberOfParticipants {
  choiceId: string;
  title: string;
  numberOfParticipants: number;
}

export interface PriceChoice {
  choiceId: string;
  title: string;
  price?: string;
  numberOfParticipantsInitialValue?: number;
}

const PriceOptionDropdown: React.FC<PriceOptionDropdownProps> = ({
  options,
  maxParticipants,
  label,
  onDropdownClose,
  onChange,
  showPrice,
  error,
  disabled,
  errorMessage,
}) => {
  const { experiments } = useExperiments();

  const isHidePriceWhenBuyinAPlanInDynamicPriceDropdownEnabled = experiments.enabled(
    ExperimentsConsts.HidePriceWhenBuyinAPlanInDynamicPriceDropdown,
  );

  const dropdownOnChange = (selectedOption: DropdownOptionProps) => {
    const selectedOptionIndex = options.findIndex(
      (option: PriceChoice) => option.choiceId === selectedOption.id,
    )!;
    setCounterValueAtIndex({
      index: selectedOptionIndex,
      value: 1,
      resetOtherCounters: true,
    });
  };

  const priceOptionToPriceOptionNumberOfParticipantsMapper = (
    priceOptions: PriceChoice[],
    useInitialValues?: boolean,
  ): PriceOptionNumberOfParticipants[] => {
    return priceOptions.map((option) => ({
      choiceId: option.choiceId,
      title: option.title,
      numberOfParticipants: useInitialValues
        ? option.numberOfParticipantsInitialValue || 0
        : 0,
    }));
  };

  const [counterValues, setCounterValues] = useState<
    PriceOptionNumberOfParticipants[]
  >(priceOptionToPriceOptionNumberOfParticipantsMapper(options, true));

  const [dropdownOptions, setDropdownOptions] = useState<DropdownOptionProps[]>(
    [],
  );
  const [multiselectDropdownOptions, setMultiselectDropdownOptions] = useState<
    MultiselectDropdownOption[]
  >([]);

  const { t } = useTranslation();
  const settings = useSettings();
  const theme =
    settings.get(settingsParams.fieldsBorderStyle) === BorderStyle.UNDERLINE
      ? DropdownTheme.Line
      : DropdownTheme.Box;

  const onExpandedChange = (isExpanded: boolean) => {
    const isCountersDropdown = maxParticipants > 1;
    if (!isExpanded && isCountersDropdown) {
      onDropdownClose?.(counterValues);
    }
  };

  const setCounterValueAtIndex = ({
    index,
    value,
    resetOtherCounters,
  }: {
    index: number;
    value: number;
    resetOtherCounters?: boolean;
  }) => {
    if (value < 0) {
      value = 0;
    }

    setCounterValues(
      (
        prevState: PriceOptionNumberOfParticipants[],
      ): PriceOptionNumberOfParticipants[] => {
        const nextState = resetOtherCounters
          ? priceOptionToPriceOptionNumberOfParticipantsMapper(options)
          : prevState;
        nextState[index] = { ...prevState[index], numberOfParticipants: value };
        const counterSumAfterChange = getSumOfCounters(nextState);

        if (counterSumAfterChange > maxParticipants) {
          nextState[index].numberOfParticipants =
            value - (counterSumAfterChange - maxParticipants);
        }
        onChange?.(nextState);
        if (maxParticipants === 1) {
          onDropdownClose?.(nextState);
        }
        return [...nextState];
      },
    );
  };

  const getPlaceholderString = () => {
    const result = counterValues
      .filter((counter) => counter.numberOfParticipants > 0)
      .map((counter) => `${counter.numberOfParticipants} ${counter.title}`);
    return (
      result.join(', ') ||
      t('app.dynamic-price.select-price-option.placeholder.text')
    );
  };

  const getSumOfCounters = (
    counterArray?: PriceOptionNumberOfParticipants[],
  ): number => {
    return (counterArray || counterValues).reduce(
      (acc, next) => acc + next.numberOfParticipants,
      0,
    );
  };

  const updateDropdownOptions = () => {
    setDropdownOptions(getDropdownOptions());
    setMultiselectDropdownOptions(getMultiselectDropdownOptions());
  };

  useEffect(() => {
    updateDropdownOptions();
  }, [
    maxParticipants,
    counterValues,
    ...(isHidePriceWhenBuyinAPlanInDynamicPriceDropdownEnabled
      ? [showPrice]
      : []),
  ]);

  const getDropdownOptions = () =>
    options.map(priceOptionToDropdownOptionMapper);

  const priceOptionToDropdownOptionMapper = (
    option: PriceChoice,
  ): DropdownOptionProps => {
    const dropdownOptionValue =
      option.title + (option.price && showPrice ? ` - ${option.price}` : '');
    return {
      id: `${option.choiceId}`,
      value: dropdownOptionValue,
      isSelectable: maxParticipants === 1,
      textOverflow: DropdownOptionTextOverflow.ellipsis,
    };
  };

  const getMultiselectDropdownOptions = (): MultiselectDropdownOption[] =>
    options.map(priceOptionToMultiselectOptionMapper);

  const priceOptionToMultiselectOptionMapper = (
    option: PriceChoice,
    i: number,
  ): MultiselectDropdownOption => {
    const openSpotsLeft = maxParticipants - getSumOfCounters();
    const counterMax = counterValues[i].numberOfParticipants + openSpotsLeft;
    return {
      ...priceOptionToDropdownOptionMapper(option),
      value: option.title,
      ...(showPrice && option.price ? { subtitle: option.price } : {}),
      counterOptions: {
        max: counterMax,
        inputAriaLabel: t(
          'app.booking-details.price-option.aria-counter-input-label',
        ),
        decrementAriaLabel: `${t(
          'app.booking-details.price-option.aria-decrease',
        )} ${option.title}`,
        incrementAriaLabel: `${t(
          'app.booking-details.price-option.aria-increase',
        )} ${option.title}`,
        numberOfParticipants: counterValues[i].numberOfParticipants,
        onChange: (newNumberOfParticipants) =>
          setCounterValueAtIndex({
            index: i,
            resetOtherCounters: false,
            value: newNumberOfParticipants,
          }),
      },
    };
  };

  const dropdownProps: Partial<DropdownProps> = {
    label,
    upgrade: true,
    theme,
    'data-hook': PriceOptionDropdownDataHooks.DROPDOWN,
    placeholder: getPlaceholderString(),
    onExpandedChange,
    onChange: dropdownOnChange,
    error,
    errorMessage,
    newErrorMessage: true,
    disabled,
  };

  return (
    <div className={st(classes.root, { theme })}>
      {maxParticipants === 1 ? (
        <Dropdown
          {...dropdownProps}
          className={st(classes.dropdown, { theme })}
          data-hook={PriceOptionDropdownDataHooks.DROPDOWN}
          options={dropdownOptions}
          initialSelectedId={
            counterValues?.find(
              (priceOptionData: PriceOptionNumberOfParticipants) =>
                priceOptionData.numberOfParticipants === 1,
            )?.choiceId
          }
        />
      ) : (
        <MultiselectDropdown
          {...dropdownProps}
          className={st(classes.multiSelect, { theme })}
          note={t('app.booking-details.price-option.subtitle', {
            maxParticipants,
          })}
          applyButtonContent={t('app.payment.promo-code.apply.text')}
          options={multiselectDropdownOptions}
        />
      )}
    </div>
  );
};

export default PriceOptionDropdown;
