export const ExperimentsConsts = {
  DynamicPricingUoU: 'specs.bookings.dynamicPricingUoU',
  DynamicPricingCustomUoU: 'specs.bookings.dynamicPricingCustomUoU',
  AlwaysShowComplexPhoneField: 'specs.bookings.AlwaysShowComplexPhoneField',
  FixFormUoUCheckboxLink: 'specs.bookings.FixFormUoUCheckboxLink',
  DayfulOverrideEcomContinueShoppingUrl:
    'specs.bookings.DayfulOverrideEcomContinueShoppingUrl',
  ImproveFormPerformance: 'specs.bookings.ImproveFormPerformance',
  Cart: 'specs.bookings.cart',
  TrimSettingParamsBookingsForm: 'specs.bookings.TrimSettingParamsBookingsForm',
  FixWidgetsNavigationAfterCheckoutWasRemoved:
    'specs.bookings.FixWidgetsNavigationAfterCheckoutWasRemoved',
  HideCouponInFormPage: 'specs.bookings.HideCouponInFormPage',
  CartBookNowButton: 'specs.bookings.CartBookNowButton',
  AddNewBiEvents: 'specs.bookings.AddNewBiEvents',
  DayfulMovePremiumBlockModal: 'specs.bookings.DayfulMovePremiumBlockModal',
  FormPageURLAPI: 'specs.bookings.formPageURLAPI',
  HideCartBookNowButtonWhenCartIsNotEmpty:
    'specs.bookings.HideCartBookNowButtonWhenCartIsNotEmpty',
  CartBookNowSettings: 'specs.bookings.CartBookNowSettings',
  V1Shutdown: 'specs.bookings.V1Shutdown',
  CancellationPolicyChangeAPI: 'specs.bookings.CancellationPolicyChangeAPI',
  SendAddressToEcomCheckoutAndCart:
    'specs.bookings.sendAddressToEcomCheckoutAndCart',
  ChangePricingPlanNavigationToNewApi:
    'specs.bookings.ChangePricingPlanNavigationToNewApi',
  MergeFirstNameAndLastNameWhenLastNameFieldDontExist:
    'specs.bookings.MergeFirstNameAndLastNameWhenLastNameFieldDontExist',
  SaveSubmissionWithDataCapsule: 'specs.bookings.SaveSubmissionWithDataCapsule',
  HidePriceWhenBuyinAPlanInDynamicPriceDropdown:
    'specs.bookings.HidePriceWhenBuyinAPlanInDynamicPriceDropdown',
  ViewCartButtonWiredToSecondary:
    'specs.bookings.ViewCartButtonWiredToSecondary',
  AppSectionParamsAdapter: 'specs.bookings.AppSectionParamsAdapter',
  DynamicFormSchema: 'specs.bookings.DynamicFormSchema',
  BookingsFormWidgetCssOptimization:
    'specs.bookings.BookingsFormWidgetCssOptimization',
};
