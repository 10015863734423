import React, { Suspense } from 'react';
import { Card } from 'wix-ui-tpa';
import { CartModalDataHooks } from '../dataHooks';
import Text, { TextType } from '../../../Text/Text';
import DateAndTime from '../../DateAndTime/DateAndTime';
import { classes, st } from './ModalContent.st.css';
import { Service } from '../../../../../utils/mappers/service.mapper';
import { Slot } from '@wix/ambassador-availability-calendar/types';
import { useEnvironment } from '@wix/yoshi-flow-editor';

const ServiceImage = React.lazy(
  () => import('../../ServiceImage/ServiceImage'),
);

export interface ModalContentProps {
  service: Service;
  slot: Slot;
  dateRegionalSettingsLocale: string;
}

export const ModalContent: React.FC<ModalContentProps> = ({
  service,
  slot,
  dateRegionalSettingsLocale,
}) => {
  const { isRTL } = useEnvironment();
  const serviceImage = service.images?.[0];
  return (
    <Card
      data-hook={CartModalDataHooks.CARD}
      className={st(classes.card, { rtl: isRTL })}
    >
      {serviceImage && (
        <Suspense fallback={<div></div>}>
          <Card.Container className={classes.left}>
            <ServiceImage
              {...serviceImage}
              dataHook={CartModalDataHooks.CARD_IMAGE}
            />
          </Card.Container>
        </Suspense>
      )}
      <Card.Container className={classes.right}>
        <div>
          <Text data-hook={CartModalDataHooks.CARD_TITLE}>{service.name}</Text>
          <DateAndTime
            totalNumberOfSessions={1}
            dateRegionalSettingsLocale={dateRegionalSettingsLocale}
            slot={slot}
            service={service}
            textType={TextType.Primary}
          />
        </div>
      </Card.Container>
    </Card>
  );
};
